
import { defineComponent, ref, computed } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { roleOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmFormLine,
    TmBadge,
    TmModal,
    TmButton,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const changeRole = ref(roleOptions[0])

    const tags = computed(() => {
      return props.users.map((el: any) => ({
        name: el.person.firstName + ' ' + el.person.lastName,
        avatar: el.person.avatar,
        avatarColor: el.person.avatarColor,
      }))
    })

    return {
      changeRole,
      roleOptions,
      tags,
    }
  },
})
